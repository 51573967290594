@import '../../../../scss/theme-bootstrap';

// Our products dropdown
.gnav-dropdown {
  @include breakpoint($landscape-up) {
    max-width: $max-width-large;
    position: relative;
    padding: 5px 4% 60px 4%;
    margin: auto;
    min-height: 300px;
    .menu__list--lvl-1 > .menu__item > .menu__link:hover:before {
      display: none;
    }
  }
  .gnav-dropdown__content {
    background: no-repeat;
    background-position: center bottom;
    &-column {
      display: block;
      float: $ldirection;
      position: relative;
      z-index: 2;
    }
    .menu-ref {
      @include transition(opacity 0.4s);
      &.inactive {
        @include opacity(0.2);
      }
      // Tout is active
      &.active {
        position: relative;
        margin-top: -10px;
        .menu-ref__title {
          @include menu__left-caret;
          font-size: 24px;
          margin-bottom: 7px;
          padding-top: 11px;
          &:after {
            content: '';
          }
        }
        .menu-ref__title-wrap {
          @include link-stub();
          display: inline-block;
          &:before,
          &:hover:before {
            bottom: -12px;
            #{$ldirection}: -5%;
            width: 108%;
            @include breakpoint($landscape-up) {
              display: none;
            }
          }
        }
        .menu__list .menu__item {
          padding: 13px 0 12px 0;
          position: relative;
          @include breakpoint($landscape-up) {
            padding: 0;
            line-height: normal;
          }
        }
      }
    }
    .menu-ref__title {
      @include menu__caret;
      position: relative;
      padding: 13px 0 12px 0;
      @include breakpoint($landscape-up) {
        padding: 0;
        line-height: normal;
        font-size: 20px;
        margin: 20px 0 0 0;
      }
      &:hover {
        cursor: pointer;
        @include breakpoint($landscape-up) {
          cursor: default;
        }
      }

      // Don't show the included menu right next to it, we're going to slide this down
      // via javascript
      & + .menu-ref__content {
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
    }
  }
}

.gnav-dropdown--menu-cols-6 {
  .gnav-dropdown__content-column {
    width: 16.6%;
  }
}

// Gnav Tout Styling
.gnav__tout {
  position: absolute;
  z-index: 1;
  &--1 {
    #{$ldirection}: 16.6%;
    top: 125px;
  }
  &--2 {
    #{$ldirection}: 21%;
    top: 125px;
  }
  &--3 {
    #{$ldirection}: 40%;
    top: 100px;
  }
  &--4 {
    #{$ldirection}: 56%;
    top: 70px;
  }
  &--5 {
    #{$ldirection}: 55%;
    top: 142px;
  }
  &--6 {
    #{$ldirection}: 73%;
    top: 65px;
  }
  &--7 {
    #{$ldirection}: 73%;
    top: 150px;
  }
  &--8 {
    #{$ldirection}: 78%;
    top: 122px;
  }
}

// Our list dropdown
.menu__item--list {
  @include breakpoint($landscape-up) {
    .menu--lvl-2 > .menu__list--lvl-2 > .menu__item--lvl-2 {
      max-width: 1280px;
      position: relative;
      padding: 10px 4% 15px 4%;
      margin: auto;
      .menu__item {
        line-height: normal;
        font-size: 20px;
        height: auto;
      }
    }
  }
}

// Gallery Dropdown - What's New Formatter Template invokes this inverted selector
.site-header__section--inverted {
  // overrides
  .site-header__main &,
  .site-header__section {
    background-color: $color-black;
    @include breakpoint($landscape-up) {
      background: none;
    }
  }
  .menu--lvl-2 {
    background-color: $color-black;
    color: $color-white;
    a {
      color: $color-white;
    }
  }
  .menu__label,
  .menu--lvl-1 .menu__list--lvl-1 > .menu__item.active > .menu__link {
    color: $color-white;
  }
  .menu--lvl-1 .menu__list--lvl-1 > .menu__item.active > .menu__link {
    @include swap_direction(margin, 0 0 15px 23px);
    @include breakpoint($landscape-up) {
      margin: 0;
    }
  }
  .menu--lvl-1 .menu__list--lvl-1 > .menu__item.active {
    padding: 10px 12px 0 12px;
    @include breakpoint($landscape-up) {
      padding: 0 8px;
    }
  }
}

.whats-new-formatter {
  @include breakpoint($landscape-up) {
    padding: 20px 0 0 0;
    position: relative;
    max-width: 1240px;
    margin: 0 auto;
  }
  &__carousel {
    @include pie-clearfix;
  }
  .basic-tout {
    float: $ldirection;
    width: 23.12812%;
    margin: 0 0 20px 0;
    @include breakpoint($landscape-down) {
      width: 48.3% !important; // Needed because element.slick('unslick') doesnt seem to remove widths
    }
    &:nth-child(odd) {
      clear: $ldirection;
    }
    &:nth-child(even) {
      float: $rdirection;
    }
    @include breakpoint($landscape-up) {
      &:nth-child(odd),
      &:nth-child(even) {
        clear: none;
        float: $ldirection;
        margin: 0 1.24792%;
      }
    }
  }
  .slick-track {
    margin: 0 auto;
  }
  .slick-prev,
  .slick-next {
    @include transform(translateY(-41%));
    top: 41%;
    position: absolute;
    z-index: 1;
    background: none;
    border: 0;
    color: $color-white;
    padding: 0;
    font-weight: bold;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: get-line-height(14px, 40px);
    font-size: 0;
    &:after {
      font-size: 20px;
    }
  }
  .slick-prev {
    @include icon('caret--left', after);
    #{$ldirection}: 0;
  }
  .slick-next {
    @include icon('caret--right', after);
    #{$rdirection}: 0;
  }
  .basic-tout__title {
    font-size: 18px;
    line-height: get-line-height(18px, 20px);
    margin: 0;
  }
  .basic-tout__text {
    padding: 7px 0 0 0;
    text-align: $ldirection;
    @include breakpoint($landscape-up) {
      padding: 10px 0;
      text-align: center;
    }
  }
  .basic-tout__img {
    @include breakpoint($landscape-up) {
      width: auto;
      margin: 0 auto;
    }
  }
}
